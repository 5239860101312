<template>
  <div class="form">
    <div class="file__row">
      <div
        class="file__block"
        v-for="(item, index) in Object.values(id.photos)"
        :key="index"
        @click.prevent="btnGallery(index, item)"
        :style="'background-image: url(' + item.thumbUrl + ')'"
      ></div>
    </div>
    <div class="file__download">
      <a 
        download
        v-for="(item, index) in id.files"
        :key="index"
        :href="item.url" 
        target="_blank"
      >
        <i class="icon-download"></i><span style="flex:1">{{item.name}}</span></a
      >
    </div>

    <Gallery
      :openGallery="openGallery"
      @close="openGallery = false"
      :img="{ list: Object.values(id.photos), index }"
    />

    <div class="btn btn-transparent" @click="open_file_select()" v-if="canEdit">
        {{$root.langText.btnAdd}}<div class="icon-plus"></div>
        <input type="file" name="file" id="file_input" style="display: none;" v-on:change="handleFileUpload($event)"/>
    </div>
    <Message
      v-if="message.popup"
      :message="message"
      @close="message.popup = false"
    />
  </div>
</template>


<style lang="scss">
</style>



<script>
import Gallery from "@/components/app/Gallery";
import Message from "@/components/app/Message";

export default {
  props: ["id"],
  data: () => ({
    canEdit: true,
    urlDomain: "",
    imgGallery: [],
    files: [],
    openGallery: false,
    index: 0,

    //message
    message: {
      status: "",
      popup: false,
      message: "",
    },
  }),


  async created() {
    this.canEdit = this.id.canEdit;
  },

  methods: {
    btnGallery(index) {
      this.index = index;
      this.openGallery = true;
    },

    open_file_select(){
      document.getElementById("file_input").click();
    },

    async handleFileUpload(dataFile){
      let id = this.id.id;

      document.querySelector('.progress_bar').style.display = 'flex';
      let type = 'order';
      let name = dataFile.target.files[0].name;

      let data = new FormData();
      data.append('file', dataFile.target.files[0]);
      var add = await this.$store.dispatch("addFile", {id, type, name, data });

      document.querySelector('.progress_bar').style.display = 'none';
      if (add.data.status == "ok") {
        this.message.status = add.data.status;
        this.message.popup = true;
        this.message.message = this.$root.langText.changes.save;
      } else {
        this.message.status = "error";
        this.message.popup = true;
        this.message.message = this.$root.langText.changes.error;
      }
      this.$emit("updateData");

    }
  },
  

  components: {
    Gallery,
    Message,
  },
};
</script>