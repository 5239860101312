<template>
  <div class="container">
    <div
      v-if="
        $root.app == 'SoftProCrmAppRioTransTest' ||
        $root.app == 'SoftProCrmAppRioTrans'
      "
    >
      <div class="tabs" :class="status ? '' : 'hide'" v-if="agreementsId">
        <ul class="tabs__caption" v-if="$route.params.id">
          <li
            @click="activetab = 1"
            v-bind:class="[activetab === 1 ? 'active' : '']"
          >
            {{ $root.langText.general }}
          </li>

          <!-- else Fraght -->
          <li
            @click="activetab = 2"
            v-bind:class="[activetab === 2 ? 'active' : '']"
            v-if="isFreight"
          >
            <div v-if="agreementsId">
              <div v-if="isAuto">
                {{ $root.langText.input.auto }}
                <span
                  v-if="
                    agreementsId.order_cars &&
                    agreementsId.order_cars.length !== '0'
                  "
                  >({{ agreementsId.order_cars.length }})</span
                >
              </div>
              <div v-else>
                {{ $root.langText.containerMin }}
                <span
                  v-if="
                    agreementsId.containers &&
                    agreementsId.containers.length !== '0'
                  "
                  >({{ agreementsId.containers.length }})</span
                >
              </div>
            </div>
          </li>
          <li
            v-if="agreementsId && $root.edit.can_view_plan_calculation && isFreight"
            @click="activetab = 3"
            v-bind:class="[activetab === 3 ? 'active' : '']"
          >
            {{ $root.langText.input.titlePlan }}
            <span
              v-if="
                agreementsId.services && agreementsId.services.length !== '0'
              "
              >({{ agreementsId.services.length }})</span
            >
          </li>

          <!-- else auto -->
          <li
            @click="activetab = 2"
            v-bind:class="[activetab === 2 ? 'active' : '']"
            v-if="!isFreight"
          >
            {{ $root.langText.customer }}
          </li>
          <li
            @click="activetab = 3"
            v-bind:class="[activetab === 3 ? 'active' : '']"
            v-if="!isFreight"
          >
            {{ $root.langText.input.carrier }}
          </li>
        </ul>

        <!-- tab 1 -->
        <div
          class="tabs__content"
          v-if="activetab === 1 && agreementsId !== null"
        >
          <AgreementsInfo
            :id="agreementsId"
            @auto="autoUpdate"
            @updateData="updateData"
          />
        </div>
        <!-- else Fraght -->
        <!-- tab 2 -->
        <div v-if="!isFreight">
          <div class="tabs__content" v-if="activetab === 2">
            <AgreementsCustomer :id="agreementsId" @updateData="updateData" />
          </div>

          <!-- tab 3 -->
          <div class="tabs__content" v-if="activetab === 3">
            <AgreementsCarrier :id="agreementsId" @updateData="updateData" />
          </div>
        </div>

        <!-- else auto -->
        <!-- tab 2 -->
        <div v-else>
          <div class="tabs__content" v-if="activetab === 2">
            <AgreementsAuto :id="agreementsId" @updateData="updateData" />
          </div>

          <!-- tab 3 -->
          <div class="tabs__content" v-if="activetab === 3">
            <AgreementsServices :id="agreementsId" @updateData="updateData" />
          </div>
        </div>

        <a @click.prevent="push()" v-if="update" class="add-chat"></a>
      </div>
    </div>

    <div v-else>
      <div class="tabs" :class="status ? '' : 'hide'" v-if="agreementsId">
        <ul class="tabs__caption" v-if="$route.params.id">
          <li
            @click="activetab = 1"
            v-bind:class="[activetab === 1 ? 'active' : '']"
          >
            {{ $root.langText.general }}
          </li>
          <li
            @click="activetab = 2"
            v-bind:class="[activetab === 2 ? 'active' : '']"
          >
            <div v-if="agreementsId">
              <div v-if="isAuto">
                {{ $root.langText.input.auto }}
                <span
                  v-if="
                    agreementsId.order_cars &&
                    agreementsId.order_cars.length !== '0' &&
                    $root.app !== 'SoftProCrmAppVedanta'
                  "
                  >({{ agreementsId.order_cars.length }})</span
                >
              </div>
              <div v-else>
                {{ $root.langText.containerMin }}
                <span
                  v-if="
                    agreementsId.containers &&
                    agreementsId.containers.length !== '0'
                  "
                  >({{ agreementsId.containers.length }})</span
                >
              </div>
            </div>
          </li>
          <li
            v-if="
              agreementsId &&
              $root.app !== 'SoftProCrmAppVedanta' &&
              $root.edit.can_view_plan_calculation
            "
            @click="activetab = 3"
            v-bind:class="[activetab === 3 ? 'active' : '']"
          >
            {{ $root.langText.input.titlePlan }}
            <span
              v-if="
                agreementsId.services && agreementsId.services.length !== '0'
              "
              >({{ agreementsId.services.length }})</span
            >
          </li>
          <li
            v-if="$root.app == 'SoftProCrmAppVedanta' || $root.app == 'SoftProCrmAppKtlukraine' || $root.app == 'SoftProCrmAppDemoTest'"
            @click="activetab = 4"
            v-bind:class="[activetab === 4 ? 'active' : '']"
          >
            {{ $root.langText.file }}
            <span v-if="fileNumber !== 0">({{ fileNumber }})</span>
          </li>
        </ul>

        <!-- tab 1 -->
        <div
          class="tabs__content"
          v-if="activetab === 1 && agreementsId !== null"
        >
          <AgreementsInfoOther
            :id="agreementsId"
            @auto="autoUpdate"
            @updateData="updateData"
          />
        </div>

        <!-- tab 2 -->
        <div class="tabs__content" v-if="activetab === 2">
          <AgreementsAuto :id="agreementsId" @updateData="updateData" />
        </div>

        <!-- tab 3 -->
        <div class="tabs__content" v-if="activetab === 3">
          <AgreementsServices
            v-if="$root.app !== 'SoftProCrmAppVedanta' && (($root.services_tied_containers && agreementsId.containers.length > 0) || !$root.services_tied_containers)"
            :id="agreementsId"
            @updateData="updateData"
          />
          <div v-else>
            <p class="list-not">{{ $root.langText.changes.fillIn_container }}</p>
          </div>
        </div>

        <!-- tab 4 -->
        <div class="tabs__content" v-if="activetab === 4">
          <AgreementsFiles
            v-if="$root.app == 'SoftProCrmAppVedanta' || $root.app == 'SoftProCrmAppKtlukraine' || $root.app == 'SoftProCrmAppDemoTest'"
            :id="agreementsId"
            @updateData="updateData"
          />
        </div>

        <a @click.prevent="push()" v-if="update" class="add-chat"></a>
      </div>
    </div>
  </div>
</template>


<style lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.decor-line {
  margin: 20px 0;
}
.tabs__caption li {
  padding: 13px 6px;
  font-size: 13px;
}
</style>

<script>
//rio
import AgreementsInfo from "@/components/agreements/AgreementsInfo";
import AgreementsCustomer from "@/components/agreements/AgreementsCustomer";
import AgreementsCarrier from "@/components/agreements/AgreementsCarrier";

//other
import AgreementsInfoOther from "@/components/agreementsOther/AgreementsInfo";
import AgreementsAuto from "@/components/agreementsOther/AgreementsAuto";
import AgreementsServices from "@/components/agreementsOther/AgreementsServices";
import AgreementsFiles from "@/components/agreementsOther/AgreementsFiles";

export default {
  data: () => ({
    activetab: 1,
    agreementsId: null,
    status: true,
    update: true,
    //Title
    globalTitle: [{ title: "Нова угода", info: "" }],
    isAuto: false,
    isFreight: false,
    fileNumber: '',
  }),

  watch: {
    async $route(to, from) {
      app.activetab = 1;
      this.createUpdatePage();
    },
  },

  async created() {
    this.createUpdatePage();
  },

  methods: {
    async createUpdatePage() {
      if (this.$route.params.id) {
        this.update = true;
        const id = this.$route.params.id;
        const res = await this.$store.dispatch("getOrdersId", id);
        this.agreementsId = res.data.detail;
        if (this.agreementsId) {
          this.status = this.agreementsId.canEdit;
          this.isFreight = this.agreementsId.isFreight == "1" ? true : false;
          this.$root.data.isFreight = this.isFreight;
          this.isAuto = this.agreementsId.isAuto_USA == "1" ? true : false;
          this.$root.data.isAuto = this.isAuto;
          

          //tehname
          this.isFreight = this.agreementsId.order_type_tech_name == "" ? true : false;
          this.isAuto = this.agreementsId.order_type_tech_name == "rio_auto" ? true : false;
          // end tehname

          if (this.$root.app == "SoftProCrmAppVedanta" || this.$root.app == 'SoftProCrmAppKtlukraine' || this.$root.app == 'SoftProCrmAppDemoTest') {
            if (
              +Object.keys(this.agreementsId.files).length +
              +Object.keys(this.agreementsId.photos).length
            ) {
              this.fileNumber =
                +Object.keys(this.agreementsId.files).length +
                +Object.keys(this.agreementsId.photos).length;
            } else {
              this.fileNumber = 0;
            }
          }
          if (res.data.detail.containers) {
            var quanity20 = 0;
            var quanity40 = 0;
            var quanityOther = 0;

            res.data.detail.containers.forEach(function (item, index) {
              if (item.type_name && item.type_name.split("0")[0] == "2") {
                ++quanity20;
              } else if (
                item.type_name &&
                item.type_name.split("0")[0] == "4"
              ) {
                ++quanity40;
              } else {
                ++quanityOther;
              }
            });
            this.agreementsId.quanity20 = quanity20;
            this.agreementsId.quanity40 = quanity40;
            this.agreementsId.quanityOther = quanityOther;
          }
          //Title
          this.$root.title = this.globalTitle;
          if (
            this.$root.app == "SoftProCrmAppRioTransTest" ||
            this.$root.app == "SoftProCrmAppRioTrans"
          ) {
            this.$root.title[0].title =
              this.$root.langText.directory.order +
              " №" +
              this.agreementsId.order_number;
          } else {
            this.$root.title[0].title =
              this.$root.langText.directory.orderOtherOne +
              " №" +
              this.agreementsId.order_number;
          }
          if (
            this.agreementsId.created_at == "0000-00-00 00:00:00" ||
            this.agreementsId.created_at == null ||
            this.agreementsId.created_at == "0000-00-00" ||
            this.agreementsId.created_at == ""
          ) {
          } else {
            this.$root.title[0].info =
              this.$root.langText.from +
              " " +
              new Intl.DateTimeFormat("ru", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }).format(
                new Date(this.agreementsId.created_at.replace(/-/g, "/"))
              );
          }
          // console.log(this.agreementsId.created_at);
        }
      } else {
        this.update = false;
        this.agreementsId = this.$root.dataFilling;
        this.$root.dataFilling = [];
        //title
        //title
        if (
          this.$root.app == "SoftProCrmAppRioTransTest" ||
          this.$root.app == "SoftProCrmAppRioTrans"
        ) {
          this.globalTitle[0].title =
            this.$root.langText.newSecondary +
            " " +
            this.$root.langText.directory.orderOne.toLowerCase();
        } else {
          this.globalTitle[0].title =
            this.$root.langText.newPrimary +
            " " +
            this.$root.langText.directory.orderOtherOne.toLowerCase();
        }
        this.$root.title = this.globalTitle;
      }
    },

    push() {
      const id = this.agreementsId.id;
      const type = "order";

      this.$router.push({
        name: "ChatMessage",
        params: { id: id + "&" + type },
      });
    },

    autoUpdate(data) {
      this.isAuto = data;
      this.isFreight = data;
    },

    async updateData() {
      const id = this.$route.params.id;
      const res = await this.$store.dispatch("getOrdersId", id);
      this.agreementsId = res.data.detail;

      if (res.data.detail.containers) {
        var quanity20 = 0;
        var quanity40 = 0;
        var quanityOther = 0;

        res.data.detail.containers.forEach(function (item, index) {
          if (item.type_name && item.type_name.split("0")[0] == "2") {
            ++quanity20;
          } else if (item.type_name && item.type_name.split("0")[0] == "4") {
            ++quanity40;
          } else {
            ++quanityOther;
          }
        });
        this.agreementsId.quanity20 = quanity20;
        this.agreementsId.quanity40 = quanity40;
        this.agreementsId.quanityOther = quanityOther;

        if (this.$root.app == "SoftProCrmAppVedanta" || this.$root.app == 'SoftProCrmAppKtlukraine' || this.$root.app == 'SoftProCrmAppDemoTest') {
          if (
            +Object.keys(this.agreementsId.files).length +
            +Object.keys(this.agreementsId.photos).length
          ) {
            this.fileNumber =
              +Object.keys(this.agreementsId.files).length +
              +Object.keys(this.agreementsId.photos).length;
          } else {
            this.fileNumber = 0;
          }
        }
      }
    },
  },

  beforeDestroy() {
    localStorage.removeItem("AgreementsClient");
    localStorage.removeItem("AgreementsClientId");
    localStorage.removeItem("AgreementsClientName");
    localStorage.removeItem("AgreementsCarrier");
    localStorage.removeItem("AgreementsCarrierId");
    localStorage.removeItem("AgreementsCarrierName");
  },

  destroyed() {
    this.$root.data.order.info = "";
    this.$root.data.order.customer = "";
    this.$root.data.order.carrier = "";
  },

  components: {
    AgreementsInfo,
    AgreementsCustomer,
    AgreementsCarrier,
    AgreementsInfoOther,
    AgreementsAuto,
    AgreementsServices,
    AgreementsFiles,
  },
  metaInfo() {
    var title = this.$root.langText.directory.orderOther;
    if (
      this.$root.app == "SoftProCrmAppRioTransTest" ||
      this.$root.app == "SoftProCrmAppRioTrans"
    ) {
      title = this.$root.langText.directory.order;
    }
    return {
      title: this.$title(title),
    };
  },
};
</script>