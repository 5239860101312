<template>
  <div>
    <form class="form">
      <h2>{{ $root.langText.info }}</h2>

      <div class="form__row" :class="$route.params.id ? 'form__row-70' : ''">
        <div class="form__input" v-if="$route.params.id">
          <input type="text" disabled v-model="fields.number" />
          <span>{{ $root.langText.input.number }}</span>
        </div>

        <div class="form__input">
          <input
            type="text"
            :disabled="$route.params.id"
            v-model="fields.date"
          />
          <span>{{ $root.langText.input.date }}</span>
        </div>
      </div>

      <div class="form__input">
        <select v-model="fields.status_id">
          <option v-for="(type, index) in status" :key="index" :value="type.id">
            {{ type.name }}
          </option>
        </select>
        <span>{{ $root.langText.input.status }}</span>
        <div class="icon icon-arrow-down"></div>
      </div>

      <!-- <div class="form__input" v-if="id.quote_id !== '0' && $route.params.id">
        <input type="text" readonly v-model="fields.basis" />
        <span>{{$root.langText.input.based}}</span>
      </div> -->

      <div v-if="agreementsId && agreementsId.quote_id">
        <!-- <a @click.prevent="puschOrders" v-if="agreementsId.quote_id == 0 && $route.params.id" class="btn-save btn btn-primary" style="margin-bottom: 20px">{{$root.langText.changes.orders}}</a> -->
        <a
          @click.prevent="puschOrders2"
          v-if="agreementsId.quote_id !== '0' && agreementsId.quote_id !== ''"
          class="btn-save btn btn-primary"
          style="margin-bottom: 20px"
          >{{ $root.langText.directory.quotesOther }} №{{
            agreementsId.quote_id
          }}
          {{ $root.langText.from }}
          {{ agreementsId.quote_created_at | date }}</a
        >
      </div>

      <div class="form__row form__row-70">
        <div class="form__input">
          <select v-model="fields.type_id" @change="changeType">
            <option
              v-for="(type, index) in types"
              :key="index"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <span>{{ $root.langText.input.type }}</span>
          <div class="icon icon-arrow-down"></div>
        </div>

        <div class="form__input">
          <select v-model="fields.select">
            <option
              v-for="(select, index) in selects"
              :key="index"
              :value="select.id"
            >
              {{ select.name }}
            </option>
          </select>
          <span>{{ $root.langText.input.view }}</span>
          <div class="icon icon-arrow-down"></div>
        </div>
      </div>

      <div class="form__input">
        <p class="form__input__textarea-p">{{ fields.cargo }}</p>
        <textarea class="form__input__textarea" v-model="fields.cargo" />
        <span>{{ $root.langText.input.cargo }}</span>
      </div>

      <!-- Vedanta -->
      <div v-if="$root.app == 'SoftProCrmAppVedanta'">
        <div class="form__input">
          <the-mask
            style="text-transform: uppercase"
            type="text"
            v-model="fields.vin"
            :mask="['XXXXXXXXXXXXXXXXX']"
          ></the-mask>
          <span>{{ $root.langText.input.vin }}</span>
        </div>
      </div>
      <!-- END Vedanta -->

      <Autocomplete
        :data="{
          name: $root.langText.input.client,
          mod: 'clients',
          key: 'client',
          input: fields.client,
          inputId: fields.client_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        v-if="$root.app !== 'SoftProCrmAppVedanta'"
        :data="{
          name: $root.langText.changes.organization,
          mod: 'all',
          modType: 'org',
          key: 'organization',
          input: fields.organization,
          inputId: fields.organization_id,
        }"
        @result="autocompleteResult"
      />
      <!-- NOT Vedanta -->
      <Autocomplete
        v-if="$root.app !== 'SoftProCrmAppVedanta'"
        :data="{
          name: $root.langText.input.legalEntity2,
          mod: 'legalPersons',
          key: 'legalEntity',
          input: fields.legalEntity,
          inputId: fields.legalEntity_id,
        }"
        @result="autocompleteResult"
      />
      <!-- END NOT Vedanta -->

      <!-- Vedanta -->
      <Autocomplete
        v-if="$root.app == 'SoftProCrmAppVedanta'"
        :data="{
          name: $root.langText.input.batch,
          mod: 'legalPersons',
          key: 'legalEntity',
          input: fields.legalEntity,
          inputId: fields.legalEntity_id,
        }"
        @result="autocompleteResult"
      />
      <!-- END Vedanta -->

      <div class="form__row form__row-50">
        <Autocomplete
          :data="{
            name: $root.langText.input.line,
            mod: 'all',
            modType: 'line',
            key: 'line',
            input: fields.line,
            inputId: fields.line_id,
          }"
          @result="autocompleteResult"
        />

        <DataInput
          :data="{
            data: fields.eta,
            input: 'eta',
            name: $root.langText.input.eta,
          }"
          @resultData="dataInput"
        />
      </div>

      <Autocomplete
        :data="{
          name: $root.langText.input.shipwreck,
          mod: 'all',
          modType: 'courtAction',
          key: 'courtAction',
          input: fields.courtAction,
          inputId: fields.courtAction_id,
        }"
        @result="autocompleteResult"
      />

      <div class="form__input">
        <input type="text" v-model="fields.booking" />
        <span>{{ $root.langText.input.booking }}</span>
      </div>

      <div class="form__input">
        <input type="text" v-model="fields.konosament" />
        <span>{{ $root.langText.input.consignment }}</span>
      </div>

      <!-- NOT Vedanta -->
      <div v-if="$root.app !== 'SoftProCrmAppVedanta'">
        <div class="form__row form__row-50">
          <div class="form__input">
            <select v-model="fields.statusMBL">
              <option
                v-for="(select, index) in statusMBL"
                :key="index"
                :value="select.id"
              >
                {{ select.name }}
              </option>
            </select>
            <span>{{ $root.langText.input.statusMbl }}</span>
            <div class="icon icon-arrow-down"></div>
          </div>

          <DataInput
            :data="{
              data: fields.dataMBL,
              input: 'dataMBL',
              name: $root.langText.input.date,
            }"
            @resultData="dataInput"
          />
        </div>

        <div class="form__input">
          <input type="text" v-model="fields.homeBL" />
          <span>{{ $root.langText.input.homeBl }}</span>
        </div>
      </div>
      <!-- END NOT Vedanta -->

      <!-- <div class="form__checkbox">
        <label>
          <input 
            type="checkbox"
            v-model="fields.control"
          >
          <span>На контроле</span>
        </label>
      </div> -->

      <!-- NOT Vedanta -->
      <div v-if="$root.app !== 'SoftProCrmAppVedanta'">
        <h2>{{ $root.langText.input.deliveryWay }}</h2>

        <div class="form__row form__row-50">
          <div style="width: 68%">
            <Autocomplete
              :data="{
                name: $root.langText.input.poo,
                mod: 'city',
                key: 'poo',
                input: fields.poo,
                inputId: fields.poo_id,
                img: id.poo_country_code,
              }"
              @result="autocompleteResult"
            />
          </div>
          <div style="width: 32%">
            <DataInput
              :data="{
                data: fields.pooData,
                input: 'pooData',
                name: $root.langText.input.date,
              }"
              @resultData="dataInput"
            />
          </div>
        </div>

        <div class="form__row form__row-50">
          <div style="width: 68%">
            <Autocomplete
              :data="{
                name: $root.langText.input.pol,
                mod: 'city',
                key: 'pol',
                input: fields.pol,
                inputId: fields.pol_id,
                img: id.pol_country_code,
              }"
              @result="autocompleteResult"
            />
          </div>
          <div style="width: 32%">
            <DataInput
              :data="{
                data: fields.polData,
                input: 'polData',
                name: $root.langText.input.date,
              }"
              @resultData="dataInput"
            />
          </div>
        </div>

        <div class="form__row form__row-50">
          <div style="width: 68%">
            <Autocomplete
              :data="{
                name: $root.langText.input.pot,
                mod: 'city',
                key: 'pot',
                input: fields.pot,
                inputId: fields.pot_id,
                img: id.pot_country_code,
              }"
              @result="autocompleteResult"
            />
          </div>
          <div style="width: 32%">
            <DataInput
              :data="{
                data: fields.potData,
                input: 'potData',
                name: $root.langText.input.date,
              }"
              @resultData="dataInput"
            />
          </div>
        </div>

        <div class="form__row form__row-50">
          <div style="width: 68%">
            <Autocomplete
              :data="{
                name: $root.langText.input.pot2,
                mod: 'city',
                key: 'pot2',
                input: fields.pot2,
                inputId: fields.pot2_id,
                img: id.pot2_country_code,
              }"
              @result="autocompleteResult"
            />
          </div>
          <div style="width: 32%">
            <DataInput
              :data="{
                data: fields.pot2Data,
                input: 'pot2Data',
                name: $root.langText.input.date,
              }"
              @resultData="dataInput"
            />
          </div>
        </div>

        <div class="form__row form__row-50">
          <div style="width: 68%">
            <Autocomplete
              :data="{
                name: $root.langText.input.pod,
                mod: 'city',
                key: 'pod',
                input: fields.pod,
                inputId: fields.pod_id,
                img: id.pod_country_code,
              }"
              @result="autocompleteResult"
            />
          </div>
          <div style="width: 32%">
            <DataInput
              :data="{
                data: fields.podData,
                input: 'podData',
                name: $root.langText.input.date,
              }"
              @resultData="dataInput"
            />
          </div>
        </div>

        <div class="form__row form__row-50">
          <div style="width: 68%">
            <Autocomplete
              :data="{
                name: $root.langText.input.fd,
                mod: 'city',
                key: 'fd',
                input: fields.fd,
                inputId: fields.fd_id,
                img: id.fd_country_code,
              }"
              @result="autocompleteResult"
            />
          </div>
          <div style="width: 32%">
            <DataInput
              :data="{
                data: fields.fdData,
                input: 'fdData',
                name: $root.langText.input.date,
              }"
              @resultData="dataInput"
            />
          </div>
        </div>
      </div>
      <!-- END NOT Vedanta -->

      <h2>{{ $root.langText.input.employeesResponsible }}</h2>

      <!-- <DataInput  :data="{data: fields.dataWork, input: 'dataWork', name: 'Дата виконаних робіт'}" @resultData="dataInput"/> -->

      <Autocomplete
        :data="{
          name: $root.langText.input.manager,
          mod: 'team',
          key: 'manager',
          input: fields.manager,
          inputId: fields.manager_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.managerOperations,
          mod: 'team',
          key: 'op_manager',
          input: fields.op_manager,
          inputId: fields.op_manager_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.assistant,
          mod: 'team',
          key: 'assistant',
          input: fields.assistant,
          inputId: fields.assistant_id,
        }"
        @result="autocompleteResult"
      />

      <div class="form__input">
        <p class="form__input__textarea-p">{{ fields.notes }}</p>
        <textarea class="form__input__textarea" v-model="fields.notes" />
        <span>{{ $root.langText.input.note }}</span>
      </div>

      <div style="display: flex; justify-content: space-between" v-if="$route.params.id">
        <a
          class="btn btn-secondary"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('copy')"
          >{{ $root.langText.copy }}</a
        >

        <a
          class="btn btn-primary"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('save')"
          >{{ $root.langText.save }}</a
        >
      </div>
      <div v-else>
        <a
          class="btn btn-primary btn-save"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('save')"
          >{{ $root.langText.save }}</a
        >
      </div>
    </form>
    <Message
      v-if="message.popup"
      :message="message"
      @close="message.popup = false"
    />
  </div>
</template>




<script>
import Autocomplete from "@/components/app/Autocomplete";
import Message from "@/components/app/Message";
import DataInput from "@/components/app/DataInput";
import { TheMask } from "vue-the-mask";

export default {
  props: ["id"],
  data: () => ({
    popup: { fade: false, mod: "Date", title: "", input: "" },
    canEdit: true,
    fields: {
      number: "",
      date: "",
      status_id: "",
      basis: "",
      type_id: "",
      select: "",
      cargo: "",
      client: "",
      client_id: "",

      organization: "",
      organization_id: "",

      legalEntity: "",
      legalEntity_id: "",

      line: "",
      line_id: "",
      eta: "",

      courtAction: "",
      courtAction_id: "",

      booking: "",
      konosament: "",
      statusMBL: "",
      dataMBL: "",
      homeBL: "",
      // control: '',

      //
      poo: "",
      poo_id: "",
      pooData: "",

      pol: "",
      pol_id: "",
      polData: "",

      pot: "",
      pot_id: "",
      potData: "",

      pot2: "",
      pot2_id: "",
      pot2Data: "",

      pod: "",
      pod_id: "",
      podData: "",

      fd: "",
      fd_id: "",
      fdData: "",

      //
      manager: "",
      manager_id: "",

      op_manager_id: "",
      op_manager: "",

      assistant: "",
      assistant_id: "",

      notes: "",

      //VEDANTA
      vin: "",
    },

    types: [],
    status: [],

    selects: [],
    statusMBL: [],

    agreementsId: null,
    //message
    message: {
      status: "",
      popup: false,
      message: "",
    },

    //select type
    params: {
      start: 0,
      limit: 20,
    },
  }),

  async created() {
    function boolean(val) {
      return val == 0 ? false : true;
    }
    if (this.$route.params.id || this.id.length !== 0) {
      this.canEdit = this.id.canEdit;
      if (this.$root.data.order.info) {
        this.agreementsId = this.$root.data.order.info;
      } else {
        this.agreementsId = this.id;
      }
      //data

      this.fields.number = this.agreementsId.order_number;
      this.fields.date = new Intl.DateTimeFormat("ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(this.agreementsId.created_at.replace(/-/g, "/")));

      if (this.$route.name == "AgreementsCreate") {
        this.fields.status_id = "";
      } else {
        this.fields.status_id = this.agreementsId.status_id;
      }
      if (this.agreementsId.quote_number) {
        this.fields.basis =
          this.$root.langText.directory.quotesOther +
          " №" +
          this.agreementsId.quote_number +
          " " +
          this.$root.langText.from +
          " " +
          new Intl.DateTimeFormat("ru", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(
            new Date(this.agreementsId.quote_created_at.replace(/-/g, "/"))
          );
      }
      this.fields.client = this.agreementsId.user_name;
      if (this.$route.params.id) {
        this.fields.type_id = this.agreementsId.type_id;
        this.fields.select = this.agreementsId.direction_type;
      } else {
        this.fields.type_id = this.agreementsId.order_type_id;
        this.fields.select = this.agreementsId.order_type_direction_id;
        this.fields.date = new Intl.DateTimeFormat("ru", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date());
        this.fields.client = this.agreementsId.client_name;
      }
      this.fields.cargo = this.agreementsId.cargo;
      var app = this;
      setTimeout(function () {
        app.fields.vin = app.agreementsId.vin;
      }, 100);

      this.fields.client_id = this.agreementsId.client_id;

      this.fields.organization = this.agreementsId.org_name;
      this.fields.organization_id = this.agreementsId.org_id;

      this.fields.legalEntity = this.agreementsId.legal_entity_name;
      this.fields.legalEntity_id = this.agreementsId.legal_entity_id;

      this.fields.line = this.agreementsId.line_name;
      this.fields.line_id = this.agreementsId.line;

      this.fields.courtAction = this.agreementsId.vessel_name;
      this.fields.courtAction_id = this.agreementsId.vessel;

      this.fields.booking = this.agreementsId.BookingReference;
      this.fields.konosament = this.agreementsId.konosament;
      this.fields.statusMBL = this.agreementsId.bl_type;
      this.fields.dataMBL = this.agreementsId.bl_date;
      this.fields.eta = this.agreementsId.eta;
      this.fields.homeBL = this.agreementsId.konosament_home;

      this.fields.poo = this.agreementsId.poo_name;
      this.fields.poo_id = this.agreementsId.poo_id;
      this.fields.pooData = this.agreementsId.date_of_poo;

      this.fields.pol = this.agreementsId.pol_name;
      this.fields.pol_id = this.agreementsId.pol_id;
      this.fields.polData = this.agreementsId.date_of_pol;

      this.fields.pot = this.agreementsId.pot_name;
      this.fields.pot_id = this.agreementsId.pot_id;
      this.fields.potData = this.agreementsId.date_of_pot;

      this.fields.pot2 = this.agreementsId.pot2_name;
      this.fields.pot2_id = this.agreementsId.pot2_id;
      this.fields.pot2Data = this.agreementsId.date_of_pot2;

      this.fields.pod = this.agreementsId.pod_name;
      this.fields.pod_id = this.agreementsId.pod_id;
      this.fields.podData = this.agreementsId.date_of_pod;

      this.fields.fd = this.agreementsId.fd_name;
      this.fields.fd_id = this.agreementsId.fd_id;
      this.fields.fdData = this.agreementsId.date_of_fd;

      this.fields.manager = this.agreementsId.manager_name;
      this.fields.manager_id = this.agreementsId.manager_id;

      this.fields.op_manager = this.agreementsId.op_manager_name;
      this.fields.op_manager_id = this.agreementsId.op_manager_id;

      this.fields.assistant = this.agreementsId.assistant_name;
      this.fields.assistant_id = this.agreementsId.assistant_id;

      this.fields.notes = this.agreementsId.comment;
    } else {
      this.fields.date = new Intl.DateTimeFormat("ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date());
    }

    if (this.$root.status.order) {
      this.status = this.$root.status.order;
    } else {
      const res2 = await this.$store.dispatch("getStatus");
      this.status = res2.data.list;
      this.$root.status.order = res2.data.list;
    }
    if (this.$root.type) {
      this.types = this.$root.type;
    } else {
      const res = await this.$store.dispatch("getOrdersTypes", this.params);
      this.types = res.data.list;
      this.$root.type = res.data.list;
    }
    if (this.$root.view) {
      this.selects = this.$root.view;
    } else {
      const res3 = await this.$store.dispatch("orderType");
      this.selects = res3.data.list;
      this.$root.view = res3.data.list;
    }
    if (this.$root.typeMBL) {
      this.statusMBL = this.$root.typeMBL;
    } else {
      const res4 = await this.$store.dispatch("typeMBL");
      this.statusMBL = res4.data.list;
      this.$root.typeMBL = res4.data.list;
    }
  },
  mounted() {},
  methods: {
    //autocomplete
    autocompleteResult(data) {
      this.fields[data.input] = data.name;
      this.fields[data.input + "_id"] = data.id;
      if (data.input == "courtAction") {
        var app = this;
        var importIn = "";
        var exportOut = "";
        this.selects.forEach(function (item, index) {
          if (app.fields.select == item.id) {
            importIn = item.is_import;
            exportOut = item.is_export;
          }
        });
        if (importIn == 1) {
          this.fields.eta = data.data.dateIn;
          this.fields.polData = data.data.dateOut;
          this.fields.podData = data.data.dateIn;
        } else if (exportOut == 1) {
          this.fields.eta = data.data.dateOut;
          this.fields.polData = data.data.dateOut;
          this.fields.podData = data.data.dateIn;
        }
      }
    },
    //date
    dataInput(data) {
      this.fields[data.input] = data.date;
    },

    changeType() {
      var app = this;
      var isAuto;
      this.types.forEach(function (item, index) {
        if (app.fields.type_id == item.id) {
          if (item.isAuto_USA == "1") {
            isAuto = true;
            app.$root.data.isAuto = true;
          } else {
            isAuto = false;
            app.$root.data.isAuto = false;
          }
        }
      });
      this.$emit("auto", isAuto);
    },

    //pusch orders
    puschOrders() {
      this.$root.dataFilling = this.agreementsId;
      this.$root.order_id = this.agreementsId.id;
      this.$router.push("/quotes/new");
    },

    puschOrders2() {
      this.$router.push({
        name: "QuotationsCreateId",
        params: { id: this.agreementsId.quote_id },
      });
    },

    //send
    async sendForm(mode) {
      function boolean(val) {
        return val == false ? 0 : 1;
      }
      //format date
      function date(date) {
        if (date) {
          var year = date.substr(6, 4);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          var resultDate =
            new Date(year + "-" + month + "-" + day).getTime() / 1000;
          return resultDate;
        }
      }

      var data = {
        status_id: this.fields.status_id || "",
        // name: this.fields.basis || '',
        type_id: this.fields.type_id,
        direction_type: this.fields.select || "",
        cargo: this.fields.cargo || "",
        vin: this.fields.vin || "",
        client_id: this.fields.client_id || "",
        org_id: this.fields.organization_id || "",
        legal_entity_id: this.fields.legalEntity_id || "",
        line: this.fields.line_id || "",
        vessel: this.fields.courtAction_id || "",
        BookingReference: this.fields.booking || "",
        konosament: this.fields.konosament || "",
        bl_type: this.fields.statusMBL || "",
        bl_date: date(this.fields.dataMBL) || "",
        konosament_home: this.fields.homeBL || "",
        eta: date(this.fields.eta) || "",
        // name: boolean(this.fields.control) || '',

        poo_id: this.fields.poo_id || "",
        date_of_poo: date(this.fields.pooData) || "",

        pol_id: this.fields.pol_id || "",
        date_of_pol: date(this.fields.polData) || "",

        pot_id: this.fields.pot_id || "",
        date_of_pot: date(this.fields.potData) || "",

        pot2_id: this.fields.pot2_id || "",
        date_of_pot2: date(this.fields.pot2Data) || "",

        pod_id: this.fields.pod_id || "",
        date_of_pod: date(this.fields.podData) || "",

        fd_id: this.fields.fd_id || "",
        date_of_fd: date(this.fields.fdData) || "",

        manager_id: this.fields.manager_id || "",
        op_manager_id: this.fields.op_manager_id || "",
        assistant_id: this.fields.assistant_id || "",
        comment: this.fields.notes || "",
      };
      if (this.$route.params.id && mode !== "copy") {
        if (this.$root.data.order.customer) {
          await this.$store.dispatch(
            "updateOrders",
            this.$root.data.order.customer
          );
        }
        data.id = this.agreementsId.id;
        var update = await this.$store.dispatch("updateOrders", data);
        this.$emit("updateData");

        //messedge
        if (update.data == "ok") {
          this.message.status = update.data;
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.save;
        } else {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.error;
        }
      } else {
        if(mode !== "copy"){
          var create = await this.$store.dispatch("createOrders", data);
        } else{
          if(this.agreementsId == null || mode == "copy"){
            if(mode !== "copy"){
              if (this.$root.quote_id == "") {
                var create = await this.$store.dispatch("createOrders", data);
              } else {
                data.quote_id = this.$root.quote_id;
                var create = await this.$store.dispatch("createOrders", data);
              }
            } else{
              this.id.id = 0;
              this.agreementsId.quote_id = '';
              this.fields.date = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date());
              this.$router.push('/orders/new');
              return
            }
          }
        }


        if (create.data.status == "error") {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.fillIn;
        } else {
          if(mode !== "copy"){
            if (this.$root.quote_id !== "") {
              const res2 = await this.$store.dispatch("getStatusQ");
              var data2 = {
                id: this.$root.quote_id,
              };
              res2.data.list.forEach(function (item, index) {
                if (item.name == "Создана сделка") {
                  data2.status_id = item.id;
                }
              });
              var update = await this.$store.dispatch("updateQuotations", data2);
            }

            if (this.agreementsId && this.agreementsId.quote_services && this.agreementsId.quote_services.length > 0) {
              for (const item of this.agreementsId.quote_services) {
                var data_serv = item;
                delete data_serv.id;
                delete data_serv.quotes_id;
                data_serv.order_id = create.data.id;
                await this.$store.dispatch("servicesAdd", data_serv);
                data_serv = [];
              }
            }
            if (this.agreementsId && this.agreementsId.services && this.agreementsId.services.length > 0) {
              for (const item of this.agreementsId.services) {
                var data_serv = item;
                delete data_serv.id;
                delete data_serv.order_id;
                data_serv.order_id = create.data.id;
                await this.$store.dispatch("servicesAdd", data_serv);
                data_serv = [];
              }
            }
          } 
          this.$router.push({
            name: "AgreementsCreateId",
            params: { id: create.data.id },
          });
          // this.$router.push("/orders");
        }
      }
    },
  },
  beforeDestroy() {
    this.$root.quote_id = "";
    //format date
    function dateSave(input, key) {
      function date(date) {
        if (date) {
          var year = date.substr(6, 4);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          var resultDate =
            new Date(year + "-" + month + "-" + day).getTime() / 1000;
          return resultDate;
        }
      }
      if (input) {
        var date = new Date(date(input) * 1000);
        var newdate =
          date.getMonth() + 1 + "." + date.getDate() + "." + date.getFullYear();
        data[key] = newdate;
      }
    }

    var data = {
      quote_id: (this.agreementsId ? this.agreementsId.quote_id : "") || "",
      status_id: this.fields.status_id || "",
      type_id: this.fields.type_id,
      direction_type: this.fields.select || "",
      cargo: this.fields.cargo || "",
      vin: this.fields.vin || "",
      client_id: this.fields.client_id || "",
      user_name: this.fields.client || "",
      org_id: this.fields.organization_id || "",
      org_name: this.fields.organization || "",
      legal_entity_id: this.fields.legalEntity_id || "",
      legal_entity_name: this.fields.legalEntity || "",
      line: this.fields.line_id || "",
      line_name: this.fields.line || "",
      vessel: this.fields.courtAction_id || "",
      vessel_name: this.fields.courtAction || "",
      BookingReference: this.fields.booking || "",
      konosament: this.fields.konosament || "",
      bl_type: this.fields.statusMBL || "",
      konosament_home: this.fields.homeBL || "",

      poo_id: this.fields.poo_id || "",
      poo_name: this.fields.poo || "",

      pol_id: this.fields.pol_id || "",
      pol_name: this.fields.pol || "",

      pot_id: this.fields.pot_id || "",
      pot_name: this.fields.pot || "",

      pot2_id: this.fields.pot2_id || "",
      pot2_name: this.fields.pot2 || "",

      pod_id: this.fields.pod_id || "",
      pod_name: this.fields.pod || "",

      fd_id: this.fields.fd_id || "",
      fd_name: this.fields.fd || "",

      manager_id: this.fields.manager_id || "",
      manager_name: this.fields.manager || "",
      op_manager_id: this.fields.op_manager_id || "",
      op_manager_name: this.fields.op_manager,
      assistant_id: this.fields.assistant_id || "",
      assistant_name: this.fields.assistant || "",
      comment: this.fields.notes || "",
    };

    dateSave(this.fields.dataMBL, "bl_date");
    dateSave(this.fields.eta, "eta");
    dateSave(this.fields.pooData, "date_of_poo");
    dateSave(this.fields.polData, "date_of_pol");
    dateSave(this.fields.potData, "date_of_pot");
    dateSave(this.fields.pot2Data, "date_of_pot2");
    dateSave(this.fields.podData, "date_of_pod");
    dateSave(this.fields.fdData, "date_of_fd");

    if (this.id) {
      data.id = this.id.id;
      data.order_number = this.id.order_number;
      data.created_at = this.id.created_at;
      if (this.id.quote_number) {
        data.quote_number = this.id.quote_number;
        data.quote_created_at = this.id.quote_created_at;
      }
    }
    this.$root.data.order.info = data;
  },

  components: {
    Autocomplete,
    Message,
    DataInput,
    TheMask,
  },
};
</script>


