<template>
  <div class="form">
    <div v-if="$root.data.isAuto">
      <!-- NOT Vedanta -->
      <div v-if="$root.app !== 'SoftProCrmAppVedanta'">
        <h4 style="color: #8eb6e3; font-size: 18px; font-weight: 500">
          {{$root.langText.directory.carList}}
        </h4>

        <Accordion
          :data="id"
          :name="{ mode: 'auto', bd_name: 'order_cars' }"
          :modCreate="'order'"
          :canEdit="canEdit"
          @update="$emit('updateData')"
        />
        <div class="decor-line"></div>
      </div>
      <!-- END NOT Vedanta -->

      <!-- Vedanta -->
      <div
        v-if="$root.app == 'SoftProCrmAppVedanta'"
        style="margin-bottom: 40px"
      >
        <div class="form__input">
          <the-mask style="text-transform: uppercase;" type="text" v-model="vin" :mask="['XXXXXXXXXXXXXXXXX']"></the-mask>
          <span>{{$root.langText.input.vin}}</span>
        </div>

        <Autocomplete
          :data="{
            name: $root.langText.input.carModel,
            mod: 'all',
            modType: 'carModels',
            key: 'auto_model',
            input: auto_model,
            inputId: auto_model_id,
          }"
          @result="autocompleteResult"
        />

        <div class="form__input">
          <input type="text" v-model="release_year" />
          <span>{{$root.langText.input.carYear}}</span>
        </div>

        <div class="form__row form__row-50">
          <div class="form__input">
            <input type="text" v-model="auto_color" />
            <span>{{$root.langText.input.color}}</span>
          </div>

          <div class="form__input">
            <input type="text" v-model="auto_mileage" />
            <span>{{$root.langText.input.mileage}}</span>
          </div>
        </div>

        <div class="form__row form__row-50">
          <div class="form__input">
            <input type="text" v-model="engine_type" />
            <span>{{$root.langText.input.typeEngine}}</span>
          </div>

          <div class="form__input">
            <input type="text" v-model="engine_volume" />
            <span>{{$root.langText.input.volumeEngine}}</span>
          </div>
        </div>


        <div class="form__row form__row-50">
          <div class="form__input">
            <input type="number" v-model="auto_price_korea" />
            <span>{{$root.langText.input.carPriceKorea}}</span>
          </div>

          <div class="form__input">
            <input type="number" v-model="auto_price_uk"
              @input="totalPrice"
            />
            <span>{{$root.langText.input.carPriceUk}}</span>
          </div>
        </div>


        <div class="form__row form__row-50">
          <div class="form__input">
            <input type="number" v-model="auto_discount" 
              @input="totalPrice"
            />
            <span>{{$root.langText.input.discount}}</span>
          </div>

          <div class="form__input form__input--not-focus">
            <input type="text" v-model="auto_total" readonly />
            <span>{{$root.langText.total}}</span>
          </div>
        </div>

        <Autocomplete
          :data="{
            name: $root.langText.input.agent,
            mod: 'counterparties',
            key: 'agent',
            input: agent,
            inputId: agent_id,
          }"
          @result="autocompleteResult"
        />

        <div class="form__checkbox">
          <label>
            <input type="checkbox" v-model="is_price_confirmed" />
            <span>{{$root.langText.input.priceConfirmed}}</span>
          </label>
        </div>

        <h4 style="color: #8eb6e3; font-size: 18px; font-weight: 500;margin-bottom: 20px">
          {{$root.langText.input.calculationPF}}
        </h4>

        <div class="form__row form__row-50">
          <div class="form__input">
            <input type="number" v-model="auto_price_not_nds" 
              @input="calculationPF()"
            />
            <span>{{$root.langText.input.ndsNot}}</span>
          </div>

          <div class="form__input form__input--not-focus">
            <input type="number" v-model="auto_nds" readonly/>
            <span>{{$root.langText.input.nds}}</span>
          </div>
        </div>

        <div class="form__row form__row-50">
          <div class="form__input form__input--not-focus">
            <input type="number" v-model="auto_price_nds" readonly/>
            <span>{{$root.langText.input.priceNds}}</span>
          </div>

          <div class="form__input form__input--not-focus">
            <input type="number" v-model="auto_pf" readonly/>
            <span>{{$root.langText.input.pf}}</span>
          </div>
        </div>
      





        <a
          class="btn-save btn btn-primary"
          v-if="canEdit"
          href="#"
          @click.prevent="save"
          >{{$root.langText.save}}</a
        >
      </div>
      <!-- END Vedanta -->

      <h4 style="color: #8eb6e3; font-size: 18px; font-weight: 500">
        {{$root.langText.directory.containersList}}
      </h4>

      <Accordion
        :data="id"
        :name="{ mode: 'containers', bd_name: 'containers' }"
        :modCreate="'order'"
        :canEdit="canEdit"
        @update="$emit('updateData')"
      />
    </div>
    <div v-else>
      <p class="info_containers__title">{{$root.langText.directory.containersNumber}}:</p>
      <ul class="info_containers info_containers--primary">
        <li>
          <span>20</span>
          <b>{{ id.quanity20 }}</b>
        </li>
        <li>
          <span>40</span>
          <b>{{ id.quanity40 }}</b>
        </li>
        <li>
          <span>{{$root.langText.other}}</span>
          <b>{{ id.quanityOther }}</b>
        </li>
      </ul>

      <Accordion
        :data="id"
        :name="{ mode: 'containers', bd_name: 'containers' }"
        :modCreate="'order'"
        :canEdit="canEdit"
        @update="$emit('updateData')"
      />
    </div>

    <Message
      v-if="message.popup"
      :message="message"
      @close="message.popup = false"
    />
  </div>
</template>



<style lang="scss">

</style>


<script>
import Accordion from "@/components/agreementsOther/Accordion";
import Autocomplete from "@/components/app/Autocomplete";
import Message from "@/components/app/Message";
import { TheMask } from "vue-the-mask";

export default {
  props: ["id"],

  data: () => ({
    canEdit: true,
    vin: "",
    auto_model: "",
    release_year: "",
    auto_model_id: "",
    auto_color: "",
    engine_type: "",
    engine_volume: "",
    auto_mileage: "",
    auto_price_korea: "",
    agent: "",
    agent_id: "",
    is_price_confirmed: false,
    //new
    auto_price_uk: "",
    auto_discount: "",
    auto_total: "",
    auto_price_not_nds: "",
    auto_nds: "",
    auto_price_nds: "",
    auto_pf: "",




    agreementsId: "",

    //message
    message: {
      status: "",
      popup: false,
      message: "",
    },
  }),

  async mounted() {
    if (this.$root.app == "SoftProCrmAppVedanta") {
      this.canEdit = this.id.canEdit;
      if (this.$root.data.order.customer) {
        this.agreementsId = this.$root.data.order.customer;
      } else {
        this.agreementsId = this.id;
      }

      function boolean(val) {
        return val == 0 ? false : true;
      }
      this.vin = this.$root.data.order.info.vin;
      this.auto_model = this.agreementsId.auto_model;
      this.release_year = this.agreementsId.release_year;
      this.auto_model_id = this.agreementsId.auto_model_id;
      this.auto_color = this.agreementsId.auto_color;
      this.engine_type = this.agreementsId.engine_type;
      this.engine_volume = this.agreementsId.engine_volume;
      this.auto_mileage = this.agreementsId.auto_mileage;
      this.auto_price_korea = this.agreementsId.auto_price_korea;
      this.agent = this.agreementsId.agent;
      this.agent_id = this.agreementsId.agent_id;
      this.is_price_confirmed = boolean(this.agreementsId.is_price_confirmed);
      
      
      //new
      this.auto_price_uk = this.agreementsId.auto_price_uk;
      this.auto_discount = this.agreementsId.auto_discount;
      this.auto_total = this.agreementsId.auto_total;
      this.auto_price_not_nds = this.agreementsId.auto_price_not_nds;
      this.auto_nds = this.agreementsId.auto_nds;
      this.auto_price_nds = this.agreementsId.auto_price_nds;
      this.auto_pf = this.agreementsId.auto_pf;
    }
  },
  methods: {
    //autocomplete
    autocompleteResult(data) {
      this[data.input] = data.name;
      this[data.input + "_id"] = data.id;
    },

    //total price
    totalPrice(){
      if(this.auto_price_uk !== '' && this.auto_discount !== ''){
        this.auto_total = (+this.auto_price_uk - +this.auto_discount).toFixed(2)
      } else{
        this.auto_total = 0
      }
    },

    //Расчет ПФ
    calculationPF(){
      this.auto_nds = (+this.auto_price_not_nds * 0.2).toFixed(2)
      this.auto_price_nds = (+this.auto_price_not_nds + +this.auto_nds).toFixed(2)
      if(+this.auto_price_not_nds <= 346830){
        this.auto_pf = (+this.auto_price_not_nds * 0.03).toFixed(2)
      } else if(+this.auto_price_not_nds <= 609580){
        this.auto_pf = (+this.auto_price_not_nds * 0.04).toFixed(2)
      } else{
        this.auto_pf = (+this.auto_price_not_nds * 0.05).toFixed(2)
      }
    },





    //save
    async save() {
      function boolean(val) {
        return val == false ? 0 : 1;
      }

      var data = {
        vin: this.vin,
        auto_model_id: this.auto_model_id,
        release_year: this.release_year,
        auto_color: this.auto_color,
        engine_type: this.engine_type,
        engine_volume: this.engine_volume,
        auto_mileage: this.auto_mileage,
        auto_price_korea: this.auto_price_korea,
        agent_id: this.agent_id,
        is_price_confirmed: boolean(this.is_price_confirmed),
        
        //new
        auto_price_uk: this.auto_price_uk,
        auto_discount: this.auto_discount,
        auto_total: this.auto_total,
        auto_price_not_nds: this.auto_price_not_nds,
        auto_nds: this.auto_nds,
        auto_price_nds: this.auto_price_nds,
        auto_pf: this.auto_pf,
      };

      if (this.$root.data.order.info) {
        await this.$store.dispatch("updateOrders", this.$root.data.order.info);
      }
      data.id = this.agreementsId.id;
      var update = await this.$store.dispatch("updateOrders", data);
      this.$emit("updateData");

      //messedge
      if (update.data == "ok") {
        this.message.status = update.data;
        this.message.popup = true;
        this.message.message = this.$root.langText.changes.save;
      } else {
        this.message.status = "error";
        this.message.popup = true;
        this.message.message = this.$root.langText.changes.error;
      }
    },
  },

  beforeDestroy() {
    if (this.$root.app == "SoftProCrmAppVedanta") {
      function boolean(val) {
        return val == false ? 0 : 1;
      }

      var data = {
        vin: this.vin,
        auto_model_id: this.auto_model_id,
        auto_model: this.auto_model,
        release_year: this.release_year,
        auto_color: this.auto_color,
        engine_type: this.engine_type,
        engine_volume: this.engine_volume,
        auto_mileage: this.auto_mileage,
        auto_price_korea: this.auto_price_korea,
        agent_id: this.agent_id,
        agent: this.agent,
        is_price_confirmed: boolean(this.is_price_confirmed),

        //new
        auto_price_uk: this.auto_price_uk,
        auto_discount: this.auto_discount,
        auto_total: this.auto_total,
        auto_price_not_nds: this.auto_price_not_nds,
        auto_nds: this.auto_nds,
        auto_price_nds: this.auto_price_nds,
        auto_pf: this.auto_pf,
      };
      this.$root.data.order.info.vin = this.vin

      if (this.id) {
        data.id = this.id.id;
      }
      this.$root.data.order.customer = data;
    }
  },

  components: {
    Accordion,
    Autocomplete,
    Message,
    TheMask,
  },
};
</script>