<template>
  <div class="form">

    <ul class="select_containers_to_services" v-if="$root.services_tied_containers && lists.length > 2">
      <li
        v-for="(list, index) in lists" 
          :key="index"
          :data-id="list.id"
          :class="list.id == active_containers ? 'active' : ''"
        @click="change_container(list.id, index)"
      >{{ list.number }}</li>
    </ul>

    <div v-if="!$root.services_tied_containers">
      <p class="info_containers__title">{{$root.langText.total}}:</p>
      <ul class="info_containers">
        <li>
          <span>{{$root.langText.changes.income}}</span>
          <b>{{Math.round(id.services_income)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.consumption}}</span>
          <b>{{Math.round(id.services_outgo)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.profit}}</span>
          <b>{{Math.round(id.services_profit)}}</b>
        </li>
      </ul>
    </div>


  
    <Accordion 
      :data="services" 
      :name="{mode: 'services', bd_name: 'services', modPage: 'orders', active_containers: active_containers}" 
      :modCreate="'order'" 
      :canEdit="canEdit"
      @update="$emit('updateData')" /> 
      
    <div style="margin-top:25px" v-if="$root.services_tied_containers">
      <p class="info_containers__title">{{$root.langText.total}}:</p>
      <ul class="info_containers">
        <li>
          <span>{{$root.langText.changes.income}}</span>
          <b>{{Math.round(services_income)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.consumption}}</span>
          <b>{{Math.round(services_outgo)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.profit}}</span>
          <b>{{Math.round(services_profit)}}</b>
        </li>
      </ul>
    </div>

  </div>
</template>


<style lang="scss">

</style>



<script>
import Accordion from '@/components/agreementsOther/Accordion'

export default {
  props: ['id'],
  data: () => ({
    canEdit: true,
    lists: [],
    active_containers: 0,
    services: {},
    services_income: 0,
    services_outgo: 0,
    services_profit: 0
  }),



  watch:{
    id: function(){
      this.total_summ(this.active_containers);
    }
  },
  
  async created() {
    this.canEdit = this.id.canEdit && this.$root.edit.can_edit_plan_calculation;

    this.total_summ(this.id.containers[0].id);

    this.lists = this.id.containers;
    this.lists = this.lists.filter(list => list.id > 0);
    this.lists.unshift({number: this.$root.langText.more_all, id: 0});
  },

  methods: {
    async change_container(id, index){
      this.total_summ(id);
    },

    total_summ(id_container){

      if(typeof this.id.services !== "undefined" && this.id.services.length > 0){
        this.active_containers = id_container;
        if(this.$root.services_tied_containers && id_container > 0){
          let services = this.id.services.filter(list => list.order_cont_id == id_container);
          this.services = {
            id: this.id.id,
            services: services
          }
        } else{
          this.services = {
            id: this.id.id,
            services: this.id.services
          }
        }
      } else{
        this.services = {
          id: this.id.id,
          services: {}
        }
      }


      this.services_income = 0;
      this.services_outgo = 0;
      this.services.services.forEach(element => {
        if(element.serviceIsNotIncludedInIncome == 0){
          this.services_income += +element.sum_income2;
        }
        if(element.serviceIsNotIncludedInCosts == 0){
          this.services_outgo += +element.sum_outgo2;
        }
      });
      this.services_profit = this.services_income - this.services_outgo;
    }
  },

  components: {
    Accordion
  }
}
</script>